const HttpsEnforcer = () => {
    const isHttps = window.location.protocol === 'https:';

    if (!isHttps) {
        window.location.replace(`https://${window.location.host}${window.location.pathname}`);
    }

    return null;
}

export default HttpsEnforcer;
