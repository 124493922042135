import PropTypes from "prop-types";
import clsx from "clsx";
import {Link} from "react-router-dom";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";


const Footer = ({
                       footerTopBackgroundColorClass,
                       footerTopSpaceTopClass,
                       footerTopSpaceBottomClass,
                       spaceLeftClass,
                       spaceRightClass,
                       backgroundColorClass,
                   }) => {

    return (
        <footer className={clsx("footer-area", backgroundColorClass, spaceLeftClass, spaceRightClass)}>
            <div className={clsx("footer-top", footerTopBackgroundColorClass, footerTopSpaceTopClass, footerTopSpaceBottomClass)}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-sm-4">
                            <FooterCopyright footerLogo="/assets/img/logo3.png" spaceBottomClass="mb-30"/>
                        </div>
                        <div className="col-xl-2 col-sm-4">
                            <div className="footer-widget mb-30 ml-30">
                                <div className="footer-title">
                                    <h3>SUPPORT</h3>
                                </div>
                                <div className="footer-list">
                                    <ul>
                                        <li>
                                            <Link to={process.env.PUBLIC_URL + "#/"}>
                                                FAQ
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={process.env.PUBLIC_URL + "#/"}>
                                                Terms and Conditions
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-2 col-sm-4">
                            <div className="footer-widget mb-30 ml-95">
                                <div className="footer-title">
                                    <h3>CONTACT</h3>
                                </div>
                                <div className="footer-list">
                                    <ul>
                                        <li>
                                            <Link to={process.env.PUBLIC_URL + "/contact"}>
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={process.env.PUBLIC_URL + "#/"}>
                                                Send Feedback
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-sm-8">
                            <FooterNewsletter
                                spaceBottomClass="mb-30"
                                spaceLeftClass="ml-50"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    backgroundColorClass: PropTypes.string,
    containerClass: PropTypes.string,
    extraFooterClass: PropTypes.string,
    spaceBottomClass: PropTypes.string,
    spaceTopClass: PropTypes.string,
    spaceLeftClass: PropTypes.string,
    spaceRightClass: PropTypes.string
};

export default Footer;
