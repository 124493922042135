import PropTypes from "prop-types";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Link} from "react-router-dom";

const BreadcrumbWrap = ({pages}) => {
    return (
        <div className="breadcrumb-area pt-35 pb-35 ml-70 mr-70 bg-gray-3">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-sm-4"></div>
                    <div className="col-xl-4 col-sm-4">
                        <Breadcrumb>
                            {pages?.map(({path, label}, i) => i !== pages.length - 1 ? (
                                <Breadcrumb.Item key={label} linkProps={{to: path}} linkAs={Link}>
                                    <div style={{ textTransform: 'uppercase', fontWeight: 600 }}>{label}</div>
                                </Breadcrumb.Item>
                            ) : (
                                <Breadcrumb.Item key={label} active>
                                    <b style={{ textTransform: 'uppercase', fontWeight: 600 }}>{label}</b>
                                </Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                    </div>
                    <div className="col-xl-4 col-sm-4"></div>
                </div>
            </div>
        </div>
    );
};

BreadcrumbWrap.propTypes = {
    pages: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired
    })).isRequired
}

export default BreadcrumbWrap;
