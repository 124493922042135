export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST'
export const DELETE_FROM_WISHLIST = 'DELETE_FROM_WISHLIST'
export const DELETE_ALL_FROM_WISHLIST = 'DELETE_ALL_FROM_WISHLIST'

// Add to wishlist
export const addToWishlist = (coin, addToast) => (dispatch) => {
  if (addToast) {
    addToast('Added Coin To Wishlist', {
      appearance: 'success',
      autoDismiss: true,
    })
  }
  dispatch({ type: ADD_TO_WISHLIST, payload: coin })
}

// Delete from wishlist
export const deleteFromWishlist = (coin, addToast) => (dispatch) => {
  if (addToast) {
    addToast('Removed Coin From Wishlist', {
      appearance: 'error',
      autoDismiss: true,
    })
  }
  dispatch({ type: DELETE_FROM_WISHLIST, payload: coin })
}

// Delete all from wishlist
export const deleteAllFromWishlist = (addToast) => (dispatch) => {
  if (addToast) {
    addToast('Removed All Coins From Wishlist', {
      appearance: 'error',
      autoDismiss: true,
    })
  }
  dispatch({ type: DELETE_ALL_FROM_WISHLIST })
}
