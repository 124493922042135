import React, {useEffect, useState} from "react";
import AuthService from "../../../services/auth.service";

const MobileGreetUser = () => {
    const [currentUser, setCurrentUser] = useState([]);

    useEffect(() => {
        let storedUser = AuthService.getUserFromLocalStorage();
        setCurrentUser(storedUser)
    }, [setCurrentUser]);
    const displayUser = currentUser ?
        <div>
            <p></p>
            <h4 className="text-center">Welcome, {currentUser.firstName} {currentUser.lastName}!</h4>
        </div> : <div></div>;

    return (
        <div className="offcanvas-mobile-greet-user-area">
            {displayUser}
        </div>
    );
};

export default MobileGreetUser;
