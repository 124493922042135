import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import clsx from "clsx";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import MobileMenu from "../../components/header/MobileMenu";
import IconGroup from "../../components/header/IconGroup";

const Header = ({
                    layout,
                    headerPaddingClass,
                    headerPositionClass,
                    headerBgClass
                }) => {
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);

    useEffect(() => {
        setHeaderTop(10);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    return (
        <header className={clsx("header-area clearfix", headerBgClass, headerPositionClass)}>
            <div className={clsx(headerPaddingClass, "sticky-bar header-res-padding clearfix", scroll > headerTop && "stick")}>
                <div className={layout === "container-fluid" ? layout : "container"}>
                    <div className="row">
                        <div className="col-xl-2 col-lg-2 col-md-6 col-4">
                            <Logo imageUrl="/assets/img/logo3.png" logoClass="logo"/>
                        </div>
                        <div className="col-xl-5 col-lg-2 d-none d-lg-block"></div>
                        <div className="col-xl-5 col-lg-8 d-none d-lg-block">
                            <NavMenu/>
                        </div>
                        <div className="d-xl-none col-lg-2 col-md-6 col-8">
                            <IconGroup/>
                        </div>
                    </div>
                </div>
                <MobileMenu/>
            </div>
        </header>
    );
};

Header.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    headerPositionClass: PropTypes.string,
    layout: PropTypes.string
};

export default Header;
