import PropTypes from "prop-types";
import React, {Fragment} from "react";
import Header from "../wrappers/header/Header";
import Footer from "../wrappers/footer/Footer";
import ScrollToTop from "../components/scroll-to-top"

const HomePageLayout = ({
                            children,
                            headerContainerClass,
                            headerBorderStyle,
                            headerPaddingClass
                        }) => {
    return (
        <Fragment>
            <Header
                layout={headerContainerClass}
                borderStyle={headerBorderStyle}
                headerPaddingClass={headerPaddingClass}
            />
            {children}
            <Footer
                backgroundColorClass="footer-white"
                spaceLeftClass="ml-70"
                spaceRightClass="mr-70"
                footerTopBackgroundColorClass="bg-gray-2"
                footerTopSpaceTopClass="pt-50"
                footerTopSpaceBottomClass="pb-40"
            />
            <ScrollToTop/>
        </Fragment>
    );
};

HomePageLayout.propTypes = {
    children: PropTypes.node,
    headerBorderStyle: PropTypes.string,
    headerContainerClass: PropTypes.string,
    headerPaddingClass: PropTypes.string
};

export default HomePageLayout;
