import bcrypt from 'bcryptjs'
import {jwtDecode} from "jwt-decode";

class AuthService {
    getUserFromLocalStorage() {
        let user = null;
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            user = JSON.parse(storedUser);
        }
        return user;
    }

    getJwtTokenFromStoredUser(user) {
        let token = null;
        if (user && user.jwtToken) {
            token = user.jwtToken;
            if (!token) {
                window.location.href = '/login';
            } else {
                const decodedToken = jwtDecode(token);
                const timeNowInSeconds = Date.now() / 1000;
                const tokenExpirationTimeInSeconds = decodedToken.exp;

                const isTokenExpired = timeNowInSeconds >= tokenExpirationTimeInSeconds;
                if (isTokenExpired) {
                    localStorage.removeItem("user");
                    localStorage.removeItem("accessToken");
                    window.location.href = '/login';
                }
            } //TODO przebudowac redirect do login
        } else {
            window.location.href = '/login';
        }
        return token;
    }

    sleep(seconds) {
        const e = new Date().getTime() + (seconds * 1000);
        while (new Date().getTime() <= e) {
            console.log("waiting");
        }
    }


    logout() {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        window.location.href = '/';
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    async hashPassword(password) {
        try {
            return await bcrypt.hash(password, "$2a$10$Q8lbYlWPDJ7sFn1zwmdwiO");
        } catch (error) {
            throw new Error('Error hashing password');
        }
    };

    async verifyPassword(password, hashedPassword) {
        try {
            return await bcrypt.compare(password, hashedPassword);
        } catch (error) {
            throw new Error('Error verifying password');
        }
    };


}

export default new AuthService();