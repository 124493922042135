import PropTypes from "prop-types";
import clsx from "clsx";

const IconGroup = ({iconWhiteClass}) => {
    const handleClick = e => {
        e.currentTarget.nextSibling.classList.toggle("active");
    };

    const triggerMobileMenu = () => {
        const offcanvasMobileMenu = document.querySelector(
            "#offcanvas-mobile-menu"
        );
        offcanvasMobileMenu.classList.add("active");
    };

    return (
        <div className={clsx("header-right-wrap", iconWhiteClass)}>
            <div className="same-style mobile-off-canvas d-block d-lg-none">
                <button className="mobile-aside-button" onClick={() => triggerMobileMenu()}>
                    <i className="pe-7s-menu"/>
                </button>
            </div>
        </div>
    );
};

IconGroup.propTypes = {
    iconWhiteClass: PropTypes.string,
};


export default IconGroup;
