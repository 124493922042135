import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import PersistProvider from "./store/providers/persist-provider";
import {store} from "./store/store";
import App from './App';
import reportWebVitals from './pages/reportWebVitals';

import 'animate.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "swiper/swiper-bundle.css";
import "./assets/scss/style.scss";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistProvider>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </PersistProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
