import PropTypes from "prop-types";
import clsx from "clsx";
import SubscribeEmail from "./sub-components/SubscribeEmail";

const FooterNewsletter = ({
  spaceBottomClass,
  spaceLeftClass,
  colorClass,
  widgetColorClass
}) => {
  return (
    <div className={clsx("footer-widget", spaceBottomClass, spaceLeftClass, widgetColorClass)}>
      <div className="footer-title text-left">
        <h3><i className="fa fa-envelope"> </i> SIGN UP FOR A NEWSLETTER</h3>
      </div>
      <div className={clsx("subscribe-style", colorClass)}>
        <p>Get E-mail updates about latest news.</p>
        <SubscribeEmail mailchimpUrl="//devitems.us11.list-manage.com/" />
      </div>
    </div>
  );
};

FooterNewsletter.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  colorClass: PropTypes.string,
  widgetColorClass: PropTypes.string
};

export default FooterNewsletter;
