import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import React, {useEffect, useState} from "react";
import AuthService from "../../services/auth.service";
import '../../styles/App.css';


const NavMenu = ({menuWhiteClass}) => {
    const {t} = useTranslation();
    const [currentUser, setCurrentUser] = useState([]);

    useEffect(() => {
        let storedUser = AuthService.getUserFromLocalStorage();
        setCurrentUser(storedUser)
    }, [setCurrentUser]);

    const {i18n} = useTranslation();
    const changeLanguageTrigger = languageCode => {
        i18n.changeLanguage(languageCode);
    };

    const logOut = () => {
        AuthService.logout();
        setCurrentUser(null)
    }

    const displayUser = currentUser ?
        <div>
            <h5 className="left-align">{currentUser.firstName} {currentUser.lastName}</h5>
            <hr/>
        </div> : '' ;

    const displaySignOut = currentUser ?
        <Link to={process.env.PUBLIC_URL + "/"} onClick={() => logOut()}>
            {t("sign_out")}
        </Link> :
        <Link to={process.env.PUBLIC_URL + "/login"}>
            <strong>{t("login")}</strong>
        </Link>;

    return (
        <div className={clsx(`main-menu ${menuWhiteClass ? menuWhiteClass : ""}`)}>
            <nav>
                <ul>
                    <li>
                        <Link to={process.env.PUBLIC_URL + "/"}>
                            {t("home")}
                        </Link>
                    </li>
                    <li>
                        <Link to={process.env.PUBLIC_URL + "/my-coins-collections/all"}>
                            {t("coin_collections")}<i className="fa fa-angle-down"/>
                        </Link>
                        <ul className="submenu">
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/my-coins-collections/all"}>
                                    {t("my_coin_collections")}
                                </Link>
                            </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/my-coins-collections/user-coin/new"}>
                                    {t("add_coin")}
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <Link to={process.env.PUBLIC_URL + "/666"}>
                            {t("medals_collections")}<i className="fa fa-angle-down"/>
                        </Link>
                        <ul className="submenu">
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/666"}>
                                    {t("my_medals_collections")}
                                </Link>
                            </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/666"}>
                                    {t("add_medal")}
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <Link to={process.env.PUBLIC_URL + "/my-account"}>
                            {t("profile")}<i className="fa fa-angle-down"/>
                        </Link>
                        <ul className="submenu">
                            <li>
                                {displayUser}
                            </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/my-account"}>
                                    {t("profile_info")}
                                </Link>
                            </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/my-coins-collections"}>
                                    {t("my_coins_collections")}
                                </Link>
                            </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/change-user-password"}>
                                    {t("manage_password")}
                                </Link>
                            </li>
                            <hr/>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/666"}>
                                    {t("send_feedback")}
                                </Link>
                            </li>
                            <li>
                                {displaySignOut}
                            </li>
                        </ul>
                    </li>

                    {/*<li>*/}
                    {/*    {displaySignOut}*/}
                    {/*</li>*/}

                    <li>
                        <Link to={process.env.PUBLIC_URL + "/"}>
                        {i18n.resolvedLanguage === "en" ? "EN" :
                                i18n.resolvedLanguage === "pl" ? "PL" :
                                    i18n.resolvedLanguage === "de" ? "DE" : ""}{" "}
                            <i className="fa fa-angle-down"/>
                        </Link>
                        <ul className="submenu">
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/"} onClick={() => changeLanguageTrigger('en')}>
                                    {t("en")}
                                </Link>
                            </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/"} onClick={() => changeLanguageTrigger('pl')}>
                                    {t("pl")}
                                </Link>
                            </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/"} onClick={() => changeLanguageTrigger('de')}>
                                    {t("de")}
                                </Link>
                            </li>
                        </ul>
                    </li>

                </ul>
            </nav>
        </div>
    );
};

NavMenu.propTypes = {
    menuWhiteClass: PropTypes.string
};

export default NavMenu;
