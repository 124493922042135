import {
  ADD_TO_WISHLIST,
  DELETE_FROM_WISHLIST,
  DELETE_ALL_FROM_WISHLIST,
} from '../actions/wishlistActions'

const initState = []

const wishlistReducer = (state = initState, action) => {
  const wishlistItems = state
  const coin = action.payload

  if (action.type === ADD_TO_WISHLIST) {
    const wishlistItem = wishlistItems.filter(
      (item) => item.id === coin.id
    )[0]

    if (wishlistItem === undefined) {
      return [...wishlistItems, coin]
    }

    return wishlistItems
  }

  if (action.type === DELETE_FROM_WISHLIST) {
    const remainingItems = (wishlistItems, coin) =>
      wishlistItems.filter((wishlistItem) => wishlistItem.id !== coin.id)

    return remainingItems(wishlistItems, coin)
  }

  if (action.type === DELETE_ALL_FROM_WISHLIST) {
    return wishlistItems.filter(() => false)
  }

  return wishlistItems
}

export default wishlistReducer
