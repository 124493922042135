import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from "./pages/LoginRegisterPage";
import ScrollToTop from "./helpers/scroll-top";
import HttpsEnforcer from "./HttpsEnforcer";

const HomePage = lazy(() => import("./pages/home/HomePage"));
const MyCoinsCollection = lazy(() => import("./pages/coins/my/MyCoinsPage"));
const CoinEdit = lazy(() => import("./pages/coins/edit/MyCoinsEditPage"));
const CoinStickyPage = lazy(() => import("./pages/coins/details/CoinStickyPage"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const ChangePasswordPage = lazy(() => import("./pages/other/ChangePasswordPage"));
const ChangeForgottenPasswordPage = lazy(() => import("./pages/other/ChangeForgottenPasswordPage"));
const RequestResetPasswordPage = lazy(() => import("./pages/other/RequestResetPasswordPage"));
const MyCoinsCollections = lazy(() => import("./pages/other/MyCoinsCollections"));
const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
    return (
        <Router>
            {/*<HttpsEnforcer />*/}
            <ScrollToTop>
                <Suspense fallback={
                        <div className="tlp-preloader-wrapper">
                            <div className="tlp-preloader">
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    }
                >
                    <Routes>
                        <Route path={process.env.PUBLIC_URL + "/"} element={<HomePage/>}/>
                        <Route path='/login' exact={true} element={<Login/>}/>

                        <Route path={process.env.PUBLIC_URL + "/my-coins-collections/all"} element={<MyCoinsCollection/>}/>
                        <Route path={process.env.PUBLIC_URL + "/my-coins-collections/user-coin/:userCoinId"} exact={true} element={<CoinEdit/>}/>
                        <Route path={process.env.PUBLIC_URL + "/my-coins-collections/user-coin/view/:id"} element={<CoinStickyPage/>}/>

                        <Route path={process.env.PUBLIC_URL + "/my-account"} element={<MyAccount/>}/>
                        <Route path={process.env.PUBLIC_URL + "/my-coins-collections"} element={<MyCoinsCollections/>}/>
                        <Route path={process.env.PUBLIC_URL + "/my-coins-collections/collection/:coinsCollectionId"} element={<MyCoinsCollections/>}/>

                        <Route path={process.env.PUBLIC_URL + "/change-user-password"} element={<ChangePasswordPage/>}/>
                        <Route path={process.env.PUBLIC_URL + "/forgot-password/request-reset-link"} element={<RequestResetPasswordPage/>}/>
                        <Route path={process.env.PUBLIC_URL + "/forgot-password/change"} element={<ChangeForgottenPasswordPage/>}/>

                        <Route path="*" element={<NotFound/>} />
                    </Routes>
                </Suspense>
            </ScrollToTop>
        </Router>
    )
}

export default App;